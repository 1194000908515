import React from "react";

const Dashboard = ({ welcome }) => {

  const { description, title } = welcome
  return (
    <div className="myAccount__content">
      <section>
        <h1>{title}</h1>
        <div className="content">
          <div dangerouslySetInnerHTML={{ __html: description }} />
        </div>
      </section>
    </div>
  );
};

export default Dashboard;
